<template>
  <v-row dense no-gutters class="d-flex justify-center align-center">
    <v-col cols="12" md="5">
      <v-img src="/artwork/remove-account.png"></v-img>
    </v-col>
    <v-col cols="12" md="5" class="d-flex justify-center flex-column">
      <form @submit.prevent="submit()">
        <v-card>
          <v-card-title>
            <div class="headline">
              {{ $lang("Remove-Account") }}
            </div>
                        
          </v-card-title>
          <v-card-text>
            <p>Do you want to remove you account? This can not be undone.</p>
              <!-- {{ $lang('') }} -->
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              :label="$lang('Concent')"
              :hint="$lang('Type `yes` if you want to remove your account from the app.')"
              v-model="user.concent"
              type="text"
              :error-messages="errors && errors.concent ? errors.concent : []"
            ></v-text-field>

            <v-text-field
              :label="$lang('AUTH:UPDATE_EMAIL:INPUT_TEXT_PASSWORD-LABEL')"
              :hint="$lang('AUTH:UPDATE_EMAIL:INPUT_TEXT_PASSWORD-HINT')"
              v-model="user.password"
              type="password"
              :error-messages="errors && errors.password ? errors.password : []"
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="mx-2 pb-4">
             <v-btn outlined type="submit" color="red">{{
                $lang("Remove-Account")
              }}</v-btn>
              <v-spacer></v-spacer>
              <v-btn text @click="$router.push({ name: 'home' })">{{
                $lang("back")
              }}</v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-col>
</v-row>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "remove-account",
  data() {
    return {
      user: { concent: null, password: null },
    };
  },
  computed: {
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  methods: {
    ...mapActions({
      removeAccount: "auth/removeAccount",
    }),
    submit() {
      if(!this.user) return;

      if(!this.user.concent || this.user.concent.length != 3 || this.user.concent.trim().toLowerCase() != 'yes'){
        let msg = 'you must write `yes` in concent field if you want to remove your account';
        alert(msg);
        return;
      }

      this.removeAccount({ user: this.user, context: this })
        .then((res) => {
          this.$router.replace({ name: "home" });
        })
        .catch((err) => {});
    },
  },
};
</script>


